import { render, staticRenderFns } from "./FormResults.vue?vue&type=template&id=609a1cb6&scoped=true"
import script from "./FormResults.vue?vue&type=script&lang=js"
export * from "./FormResults.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "609a1cb6",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/render/project/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('609a1cb6')) {
      api.createRecord('609a1cb6', component.options)
    } else {
      api.reload('609a1cb6', component.options)
    }
    module.hot.accept("./FormResults.vue?vue&type=template&id=609a1cb6&scoped=true", function () {
      api.rerender('609a1cb6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/settings/forms/FormResults.vue"
export default component.exports