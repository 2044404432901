<template>
  <survey :survey="survey" />
</template>

<script>
import { Model } from 'survey-vue';
import 'survey-vue/defaultV2.min.css';

export default {
  name: 'FormResults',
  props: [
    'formItemId',
    'formContent',
    'formResults',
  ],
  data() {
    const survey = new Model(this.formContent);
    // eslint-disable-next-line no-unused-vars
    survey.onComplete.add((sender, options) => {
      console.log(JSON.stringify(sender.data, null, 3));
    });
    survey.data = this.formResults;
    survey.mode = 'display';

    return {
      survey,
    };
  },
};
</script>

<style scoped>

</style>
